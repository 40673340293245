import AboutsImg from '../assets/full-stack-developer-icon.webp';

export default function Resume(){
return <section className='flex flex-col md:flex-row bg-primary px-5 '>
    <div className='py-5 md:w-1/2 flex justify-center'>
        <img className='w-[300px]' src={AboutsImg}/>
    </div>
    <div className='md:w-1/5 flex justify-end'>
        <div className='flex flex-col justify-center text-white'>
        <h1 className='text-4xl  border-b-4 border-primary mb-5 w-[170px] font-bold'>Resume</h1>
       <p className='pb-5'> you con view my resume<a className='btn' href='#'>Download</a></p>
  
    </div>
</div>
</section>
}
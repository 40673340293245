import AboutsImg from '../assets/full-stack-developer-icon.webp';

export default function Resume(){
return <section className='flex flex-col  bg-primary px-5 py-32 text-white'>
    <div className='flex flex-col items-center'>
    
        <h1 className='text-4xl  border-b-4 text-white border-secondary mb-5 w-[170px] font-bold'>Contact</h1>
       <p className='pb-5'>if you  want to discuss more in detail,please contact me</p>
        <a href='mailto:tn63jega@gmail.com' className='py-2'><span className='font-bold'>Email :</span>tn63jega@gmail.com</a>
        <a href='tel:+918248638767' className='py-2'><span className='font-bold'>phone :</span>+91 8248638767</a>

    </div>

</section>
}
import HeroImg from '../assets/full-stack-developer-am-arbeiten-software-engineer.jpg'
import { FaFacebookSquare,FaLinkedin,FaTwitterSquare,FaGithub,FaWhatsapp} from "react-icons/fa";


export default function Hero(){
    return <section className='flex  px-5 py-32 bg-primary bg-secondary justify-center'>
      
      <div className='w-1/2 flex flex-col justify-center ' >
        <h1 className='W-1/2 text-white text-4xl font-hero-font'>Hi,<br/>Im <span className='text-black'>JEGATHEESAN</span>
        <p className='text-2xl'>Im a Java Full-Stack Developer</p>
        </h1>
        <div className='flex '>
            <a href='#' className='pr-5 pt-5 hover:text-white'> <FaFacebookSquare size={40} /></a>
            <a href='#' className='pr-5 pt-5 hover:text-white'> <FaLinkedin size={40} /></a>
            <a href='#' className='pr-5 pt-5 hover:text-white'> < FaTwitterSquare size={40}/></a>
            <a href='#' className='pr-5 pt-5 hover:text-white'> < FaGithub size={40}/></a>
            <a href='#' className='pr-5 pt-5 hover:text-white'> < FaWhatsapp size={40}/></a>



        </div>
        </div>
        <img className='md:w-1/8' src={HeroImg}/> 
    </section>
}
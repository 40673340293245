import AboutsImg from '../assets/full-stack-developer-icon.webp';

export default function About(){
return <section className='flex flex-col md:flex-row bg-primary px-5  '>
    <div className='md:w-1/2'>
        <img src={AboutsImg}/>
    </div>
    <div className='md:w-1/3 flex justify-center'>
        <div className='flex flex-col justify-center text-white'>
        <h1 className='text-4xl  border-b-4 border-primary mb-5 w-[170px] font-bold'>About me</h1>
       <p className='pb-5'>Hi,my name is JEGATHEESAN.I am a JAVA FULL STACK DEVELOPER.I beautiful websites with React.js and Tailwind CSS.</p>
    <p className='pb-5'>I am proficiend in Frondend skills like React.js,Redex,Redex Tool Kit,Axios,Tailwind CSS,SaSS,Css3 and many more.</p>
    <p className='pb-5'>In Backend i know java,SpringBoot,SQL,and JIRA Tool. </p>
    </div>
</div>
</section>
}
import React from 'react'
import './App.css';
import Header from "./components/Header";
import Hero from "./components/Hero";
import Abouts from './components/Abouts';
import Projects from './components/Projects';
import Resume from './components/Resume';
import Contact from '../src/components/Contact'

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Abouts/>
      <Projects/>
      <Resume/>
      <Contact/>
    </div>
  );
}   

export default App;


import { Bars3Icon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import {Link} from 'react-router-dom'
export default function Header() {
    
    const [toggleMenu,settoggleMenu]=useState(false);

    return <header  className="flex justify-between px-5 py-2 bg-primary">

        <Link className="font-bold text-black" href="#">jegatheesan</Link>
       <nav className="hidden md:block">
        <ul className="flex text-white">
            <li><Link href="#">Home</Link></li>
            <li><Link href="#">About</Link></li>
            <li><Link href="#">projects</Link></li>
            <li><Link href="#">Resume</Link></li>
            <li><Link href="#">Contact</Link></li>
            
        </ul>
        </nav>
       {toggleMenu &&<nav className="block md:hidden ">
        <ul className="flex flex-col text-white  mobile-nav">
            <li><Link href="#">Home</Link></li>
            <li><Link href="#">About</Link></li>
            <li><Link href="#">projects</Link></li>
            <li><Link href="#">Contact</Link></li>
            
        </ul>
        </nav>}
        <button onClick={()=>settoggleMenu(!toggleMenu)} className='block md:hidden'><Bars3Icon className="text-white h-5"/></button>
    </header>
}

import ProjectsImg1 from '../assets/240.jpg'
import ProjectsImg2 from '../assets/full stack.png'
import ProjectsImg3 from '../assets/full stack.png'

export default function Projects(){
    return <section className="flex flex-col py-20 px-5 justify-center bg-secondary text-white">
        <div className="w-full">
            <div className="flex flex-col px-10 py-5">
            <h1 className="text-4xl border-b-4 border-secondary mb-5 w-[160px] font-bold">Projects</h1>
             <p>opacity-0 duration-100 hover:opacity-100</p>
        </div>
        </div>
        <div className="w-full">
            <div className='flex flex-col md:flex-row  px-10 gab-5'>
                <div className='relative'>
            <img className='h-[200px] w-[500px]' src={ProjectsImg1}/>
           <div className='Projects-desc'>
              <p className='text-center px-5 py-5'>A Ecommerce Website.Built with MERN Stack.</p>
           </div>
            </div>

            <div className='relative'> 
          <img className='h-[200px] w-[500px]' src={ProjectsImg3}/>
           <div className='Projects-desc'>
           <p className='text-center px-5 py-5'>VM Castel Website.</p>
           </div>
           
            </div>
            <div className='relative'>
            <img className='h-[200px] w-[500px]' src={ProjectsImg2}/>
           <div className='Projects-desc'>

           <p className='text-center px-5 py-5'>VM Castel Website.</p>
           </div>
           
           
           </div>
            </div>
        </div>
    </section>
}